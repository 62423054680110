<template>
  <el-table
    :class="{ show_empty: showEmpty }"
    v-loading="tableLoading"
    :data="taskTableData"
    :max-height="tableHeight"
    highlight-current-row
    @row-click="rowClick"
    empty-text=" "
    border
    header-cell-class-name="header-row-cell"
  >
    <el-table-column type="index" :index="indexMethod" :label="$t('publicManagement.No')" align="left" width="88px"></el-table-column>

    <el-table-column :label="$t('publicManagement.taskItem')" align="left" min-width="200">
      <template slot-scope="scope">
        <div style="display: flex; align-content: center">
          <span style="margin-left: -4px; margin-right: 8px"> </span>
          <span v-if="!choosedThisRow(scope.$index, 'taskItem')">{{ scope.row.taskItem }}</span>
          <el-input v-else class="hours-input" v-model="scope.row.taskItem"></el-input>
        </div>
      </template>
    </el-table-column>

    <el-table-column :label="$t('publicManagement.operation')" align="left">
      <template slot-scope="scope">
        <div>
          <span v-if="updatePermission" size="mini" class="deleteRow" @click="deleteRow(scope.$index, taskTableData)">删除</span>
          <el-button v-else size="mini" disabled>无操作权限</el-button>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  components: {},
  props: {
    tableHeight: {
      type: Number,
      default: 0,
    },
    tableLoading: {
      type: Boolean,
      default: false,
    },
    updatePermission: {
      type: Boolean,
      default: false,
    },
    taskTableData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      taskId: '',
      showEmpty: false,
    };
  },
  computed: {},
  watch: {
    taskTableData: {
      deep: true,
      immediate: false,
      handler(val) {
        !val.length ? (this.showEmpty = true) : (this.showEmpty = false);
      },
    },
  },

  methods: {
    deleteRow(index, rows) {
      this.$confirm('<p>此操作将删除该任务项, 是否继续?</p><p>(删除后记得点击保存哦！)</p>', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          rows.splice(index, 1);
          this.$message({
            type: 'success',
            message: '已确定删除，记得保存!',
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除！',
          });
        });
    },
    indexMethod(index) {
      return index + 1 < 10 ? '0' + (index + 1) : index + 1;
    },
    //记录点击了哪一行
    rowClick(row, column, event) {
      console.log(row, column, event);
      this.taskId = row.taskId;
    },
    /* 把选中的行的某些单元格替换为输入框 */
    choosedThisRow(idx, key) {
      return this.updatePermission && (this.taskId === this.taskTableData[idx].taskId || this.taskTableData[idx][key] === '');
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep.el-table {
  //table_header
  .header-row-cell {
    border-bottom: none !important;
  }
  .el-table__header-wrapper {
    border-radius: 4px;
    border-bottom: 1px solid #e8eaec;
    .el-table__header th.el-table__cell {
      background-color: #f8f8f8f9;
      color: #515a6e;
      font-weight: 600;
      padding: 6px 0;
      height: 48px;
      box-sizing: border-box;
      border-bottom: 1px solid #e8eaec;
    }
  }
  /* 
  .el-table__empty-block {
    min-height: 350px;
  } */

  //table_body
  .el-table__body td.el-table__cell {
    cursor: pointer;
    padding: 7px 0;
    height: 49px;
    border-color: #e8eaec;

    .hours-input {
      .el-input__inner {
        color: #515a6e;
        border: none;
        text-align: left;
        height: 32px;
        line-height: 32px;
        background-color: #fff;
      }
    }

    .deleteRow {
      // width: 126px;
      // height: 20px;
      color: #2d8cf0;
    }
  }
}
</style>
