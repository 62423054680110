<template>
  <div class="public-management__page">
    <div class="tree-sub" ref="tree-sub">
      <div class="tree-menu" :style="treeHeight">
        <TreeMenu
          :mutipleCheck="true"
          :treeMenuName="$t('publicAffairs.affair')"
          :searchName="$t('publicManagement.findAffair')"
          :projectArr="projectArr"
          :allUser="allUser"
          :choosedContractItem="choosedContractItem"
          :createPermission="createPermission"
          @addProject="addProject"
          @getChoosedContractItem="getChoosedContractItem"
        />
      </div>
    </div>
    <div class="app-box" :style="{ width: tableWidth + 'px !important' }">
      <div class="top-box" ref="headerBtns">
        <div class="header">
          <div class="top-header-tit">
            <p class="editContract">{{ $t('publicManagement.editAffair') }}</p>

            <div @click="toggleExpand" class="tit-container expandControl">
              <span>{{ expanded ? $t('commonVariables.fold') : $t('commonVariables.unfold') }}</span
              ><i :class="expanded ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
            </div>
          </div>

          <div v-if="projectArr.length && updatePermission" class="header-btns">
            <el-button size="mini" @click="concelEdit" class="concelbtn">{{ $t('publicManagement.cancel') }}</el-button>
            <el-button size="mini" @click="saveEdit('ruleForm')" class="savebtn" type="primary">{{ $t('publicManagement.save') }}</el-button>
          </div>
        </div>
        <TransitionCollapse @expandOver="expandOver" @retractOver="expandOver" v-show="expanded" :expandHeight="expandHeight">
          <el-form :model="ruleForm" ref="ruleForm" :rules="rules" class="edit_from" label-position="top">
            <el-form-item prop="affairName" label="事项名称" class="affair-formItem">
              <el-input v-model="ruleForm.affairName" clearable placeholder="请输入" :disabled="!updatePermission"></el-input>
            </el-form-item>

            <el-form-item prop="directorSelected" label="负责人">
              <el-select v-model="ruleForm.directorSelected" :disabled="!updatePermission" filterable clearable>
                <el-option v-for="item in allUser" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </el-form-item>

            <el-form-item prop="secondDirectorSelected" label="第二负责人">
              <el-select v-model="ruleForm.secondDirectorSelected" :disabled="!updatePermission" filterable clearable>
                <el-option v-for="item in allUser" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="面向人员" prop="personnelOriented" class="personnelOriented-class">
              <el-radio-group v-model="ruleForm.personnelOriented" class="persion-group">
                <el-radio-button v-for="i in personnelBox" :label="i.value" :key="i.value">{{ i.label }}</el-radio-button>
              </el-radio-group>
              <memberSelect
                :disabledUpdate="!updatePermission"
                :memberSelected="ruleForm.userIds"
                :allUser="allUser"
                :projectUpdate="true"
                @changeMemberSelected="changeMemberSelected"
                v-show="ruleForm.personnelOriented === 1"
              />
            </el-form-item>
          </el-form>
        </TransitionCollapse>
      </div>
      <div class="heat-shield"></div>
      <div class="bottom-box">
        <div class="header" ref="header">
          <div class="header-tit">{{ $t('publicManagement.editTaskItem') }}</div>
          <div v-if="projectArr.length && updatePermission" class="header-btns">
            <el-button size="mini" @click="addTaskItem" class="createNewbtn">{{ $t('publicManagement.createNewTaskItem') }}</el-button>
            <!-- <el-button size="mini" @click="leadingInItem" class="leadingInbtn">{{ $t('publicManagement.leadingInTaskItem') }}</el-button> -->
            <el-button size="mini" @click="saveAllTaskItems" class="savebtn" type="primary">{{ $t('publicManagement.save') }}</el-button>
          </div>
        </div>

        <EditTaskItemTable
          :tableHeight="tableHeight"
          :tableLoading="tableLoading"
          :taskTableData="taskTableData"
          :updatePermission="updatePermission"
        />
      </div>
    </div>
  </div>
</template>
<script>
import TreeMenu from '@/components/TreeMenu/TreeMenu.vue';
import EditTaskItemTable from './components/EditTaskItemTable.vue';
import memberSelect from '@/components/memberSelect/index.vue';
import projectManagementApi from '@/api/modules/projectManagement.api.js';
import publicManagementApi from '@/api/modules/publicAffairsManagement.api.js';
import TransitionCollapse from '@/components/common/TransitionCollapse.vue';
import { isEmpty } from '@/utils/rule-verification';

import { mixinsWorkRecord } from '@/mixins/treeMenu';

export default {
  name: 'PublicManagement',
  mixins: [mixinsWorkRecord],
  components: { TreeMenu, EditTaskItemTable, memberSelect, TransitionCollapse },
  data() {
    const validUserId = (rule, value, callback) => {
      if ((value.length <= 0 || !value) && this.ruleForm.personnelOriented === 1) {
        callback(new Error('部分导出下员工姓名必选'));
      } else {
        callback();
      }
    };
    return {
      tableWidth: 0,
      expandHeight: 500,
      tableHeight: 0,
      expanded: true,
      // treeMenuName:'',
      // searchName:'',
      projectArr: [], //项目列表
      choosedContractItem: {},
      allUser: [], //返回的所有供选择的人
      //校验表单规则
      ruleForm: {
        staffs: [],
        affairName: '',
        directorSelected: '', //负责人
        secondDirectorSelected: '', //第二负责人
        userIds: [],
        // 面向员工相关
        personnelOriented: 0,
      },
      rules: {
        affairName: [{ required: true, message: '请输入项目名称', trigger: 'blur' }],
        directorSelected: [{ required: true, message: '请选择负责人', trigger: 'change' }],
        secondDirectorSelected: [{ required: true, message: '请选择首席项目管理人', trigger: 'change' }],
        userIds: [{ validator: validUserId, trigger: 'change' }],
        personnelOriented: [{ required: true, message: '请选择面向人员', trigger: 'change' }],
      },

      personnelBox: [
        { label: '部分员工', value: 1 },
        { label: '全体员工', value: 0 },
      ],

      taskTableData: [],
      tableLoading: false,

      // // 导入
      // leadingInForm: { fileList: [], ruleCheck: [] },
      // leadingInRules: { ruleCheck: [{ required: true, validator: validatefile, trigger: 'change' }] },
      // leadingInVisable: false,
      // uploadFd: null,
      // resError: false,
      // errorList: [],
    };
  },
  computed: {
    updatePermission() {
      return this.$store.state.app.userConfig.permissionsKeys?.PublicManagementUpdate;
    },
    createPermission() {
      return this.$store.state.app.userConfig.permissionsKeys?.PublicManagementCreate;
    },
  },

  created() {
    this.getSearchByUserName();
    this.getAllAffairs(true);
  },
  mounted() {},
  methods: {
    calTableRangeWidth() {
      this.tableWidth = window.innerWidth - (this.$refs['tree-sub']?.offsetWidth ?? 0) - 16 - 28 - (this.$store.getters.sidebar.opened ? 208 : 55);
    },
    expandOver() {
      this.$nextTick(() => {
        this.calTableDom();
      });
    },

    toggleExpand() {
      this.expanded = !this.expanded;
    },
    calTableDom() {
      // 上导航(包含内外边距) 路由菜单滑轨(包含内外边距) ref->top-box,header bottom-box的内边距和外边距
      this.otherDomHeight = (this.$refs.headerBtns?.offsetHeight ?? 0) + (this.$refs.header?.offsetHeight ?? 0) + 32 + 50 + 32 + 12 + 12;
      this.tableHeight = window.innerHeight - this.otherDomHeight;
    },

    changeMemberSelected(vals, ids) {
      this.ruleForm.userIds = [...ids];
    },
    //按名字检索用户
    async getSearchByUserName() {
      let [data] = await projectManagementApi.getSearchUserName({ page: 1, pagesize: 99999 });
      if (!data) return;
      this.allUser = [...data.user];
    },

    //获取所有事项列表
    async getAllAffairs(getFirstData = true) {
      let [data] = await publicManagementApi.getPublicAffairs();
      if (!data) return;
      this.projectArr = [...data.affair];

      //默认获取第一个合同的数据
      if (getFirstData) {
        this.choosedContractItem = this.projectArr[0];
      }
      if (this.projectArr.length) {
        this.getAffairDetail(this.choosedContractItem.id);
        this.getChoosedContractItem(this.choosedContractItem);
      }
    },

    //获取事项的详情
    async getAffairDetail(id) {
      let [data] = await publicManagementApi.getAffairDetail({ id: id });
      if (!data) return;
      this.ruleForm.affairName = data.affair.name;
      this.ruleForm.personnelOriented = data.affair.showRange;
      this.ruleForm.userIds = data.staffs.map(item => {
        return item.id;
      });
      /*       this.memberSelected = data.staffs.map(item => {
        return item.id;
      }); */
      this.ruleForm.directorSelected = data.primOwner.length ? data.primOwner[0].id : '';
      this.ruleForm.secondDirectorSelected = data.secOwner.length ? data.secOwner[0].id : '';

      this.taskTableData = data.tasks.map(i => {
        return {
          taskId: i.id,
          taskItem: i.name,
        };
      });
    },

    //获取选择的那个项目
    getChoosedContractItem(i) {
      this.choosedContractItem = Object.assign({}, i);
      this.getAffairDetail(this.choosedContractItem.id);
    },
    //添加项目触发更新
    addProject() {
      this.getAllAffairs(false);
    },

    //撤销更改
    concelEdit() {
      this.getAffairDetail(this.choosedContractItem.id);
      this.$message.info('已取消修改！');
    },

    //保存编辑后的事项
    saveEdit(formName) {
      if (!this.ruleForm.affairName || !this.ruleForm.directorSelected || !this.ruleForm.secondDirectorSelected) {
        this.$message.error('有必填信息未填！');
        return;
      }
      let q = {
        affair: {
          id: this.choosedContractItem.id,
          name: this.ruleForm.affairName,
          showRange: this.ruleForm.personnelOriented,
        },
        primOwner: this.ruleForm.directorSelected
          ? [
              {
                id: this.ruleForm.directorSelected,
              },
            ]
          : [],
        secOwner: this.ruleForm.secondDirectorSelected ? [{ id: this.ruleForm.secondDirectorSelected }] : [],
        staffs: this.ruleForm.personnelOriented === 1 ? this.ruleForm.userIds : undefined,
      };

      this.$refs[formName].validate(async valid => {
        if (valid) {
          let [data] = await publicManagementApi.saveAffair(q);
          if (!data || Object.keys(data).length === 0) {
            this.$message.error('保存失败！');
            return;
          }
          this.$message.success('保存成功！');
          this.ruleForm.personnelOriented = data.affair.showRange;
          this.ruleForm.directorSelected = data.primOwner ? data.primOwner[0].id : '';
          this.ruleForm.secondDirectorSelected = data.secOwner ? data.secOwner[0].id : '';
          this.getAllAffairs(false);
        } else {
          return false;
        }
      });
    },

    /* ---编辑任务项--- */
    //添加任务项
    addTaskItem() {
      this.$prompt(this.$t('publicManagement.createTaskItems_Tips'), this.$t('publicManagement.createNewTaskItem'), {
        confirmButtonText: this.$t('commonVariables.complete'),
        cancelButtonText: this.$t('commonVariables.cancel'),
        inputValidator: v => !isEmpty(v) || 'Can not be empty',
      })
        .then(async ({ value }) => {
          console.log(value);

          this.taskTableData.unshift({
            taskItem: value,
          });
          this.$message({
            type: 'success',
            message: '记得点击保存才创建成功哦！',
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消创建！',
          });
        });
    },

    //保存任务项
    async saveAllTaskItems() {
      let s = {
        affair: this.choosedContractItem.id,
        tasks: [],
      };

      for (let obj of this.taskTableData) {
        s.tasks.push({ id: obj.taskId, name: obj.taskItem });
      }
      let [data] = await publicManagementApi.saveAffairTask(s);
      if (!data) return;
      this.$message.success('保存成功！');
      this.getAffairDetail(this.choosedContractItem.id);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.el-select-dropdown__item {
  font-family: Avenir, Helvetica, Arial, sans-serif !important;
}
::v-deep .el-input__suffix {
  display: flex;
  align-items: center;
}
::v-deep .el-input__inner {
  color: #515a6e;
  height: 32px;
  line-height: 32px;
  vertical-align: middle;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  font-size: 14px;
}
::v-deep .el-icon-arrow-up {
  line-height: 32px;
}
::v-deep .el-icon-date {
  line-height: 32px;
}
.public-management__page {
  position: relative;
  max-height: 100%;
  display: flex;
  .tree-sub {
    margin-left: 24px;
    .tree-menu {
      ::v-deep .tree-menu__box {
        .tit {
          // height: 68px;
          // line-height: 80px;
          padding-top: 22px;
          margin-bottom: 6px;
        }
      }
    }
  }

  .app-box {
    width: 100%;
    margin: 0 16px;
    background-color: #fff;
    overflow-y: hidden;
    border-radius: 2px;
    flex: 1;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 14px;
      height: 51px;
      .header-tit {
        height: 16px;
        line-height: 16px;
        font-size: 16px;
        font-weight: 600;
        color: #19233b;
      }
      .header-btns {
        display: flex;
        align-items: center;
        height: 36px;
        font-size: 14px;
        .createNewbtn,
        .concelbtn,
        .savebtn {
          margin-left: 16px;
          height: 32px;
          padding: 0 21.5px;
          // line-height: 30px;
        }
      }
    }

    .top-box {
      background-color: #fff;
      padding: 12px 32px;
      border-radius: 2px;
      .header {
        border-bottom: 1px solid #e8eaec;
        .top-header-tit {
          font-size: 16px;
          font-weight: 600;
          color: #19233b;

          .expandControl {
            display: inline-block;
            margin-left: 12px !important;
            color: #409eff;
            cursor: pointer;
            font-size: 14px;
            span {
              padding-right: 5px;
            }
          }
          .editContract {
            display: inline-block;
            margin: 0;
            padding-left: 0;
            font-size: 16px;
            font-weight: 700;
            color: #19233b;
          }
        }
      }
      .edit_from {
        display: flex;
        flex: auto;
        justify-content: flex-start;
        flex-wrap: wrap;
        font-size: 14px;

        ::v-deep .el-form-item__content {
          line-height: 32px;
        }
        .affair-formItem {
          min-width: 283px !important;
        }
        .el-form-item {
          margin: 0;
          margin-right: 30px;
          max-width: 224px;
          padding: 10px 0;
          ::v-deep .el-form-item__label {
            color: #515a6e;
            font-weight: normal;
            font-size: 14px;
            height: 20px;
            padding: 0;
            line-height: 20px;
            margin-top: 0;
            margin-bottom: 7px;
          }
          ::v-deep .el-select {
            width: 100%;
          }
          ::v-deep .el-date-editor--daterange {
            width: 100%;
          }
        }
        .personnelOriented-class {
          margin-right: 0;
          min-width: 400px;
          .persion-group {
            min-width: 120px;
            padding-right: 12px;
            ::v-deep .el-radio-button__inner {
              height: 32px;
              padding: 0 8px;

              line-height: 30px;
            }
          }
          ::v-deep .el-form-item__content {
            display: flex;
            flex: auto;
            .memberSelectTags {
              width: 214px !important;
            }
          }
        }
      }
    }
    .heat-shield {
      background: #f8f8f8f9;
      height: 16px;
      width: 100%;
    }
    .bottom-box {
      margin-top: 12px;
      background-color: #fff;
      padding: 0 32px;
      border-radius: 2px;
    }
  }
}

@media screen and (min-width: 350px) and (max-width: 900px) {
  ::v-deep .tree-menu__box {
    max-width: 188px !important;
  }
  .app-box {
    flex: auto !important;
    background-color: #fff;
    padding: 0 !important;
    border-radius: 2px;
    margin: 0 16px !important;
    margin-right: 12px !important;
    margin-top: 0 !important;
    .bottom-box {
      margin-top: 0 !important;
      padding: 12px 14px !important;
    }
  }
}
@media screen and (min-width: 900px) and (max-width: 1550px) {
  ::v-deep .tree-menu__box {
    max-width: 198px !important;
  }
  .tree-sub {
    margin-left: 16px !important;
  }
  .app-box {
    flex: auto !important;
    background-color: #fff;
    padding: 0 !important;
    border-radius: 2px;
    margin: 0 16px !important;
    margin-right: 12px !important;
    margin-top: 0 !important;
  }
  .bottom-box {
    padding: 4px 12px !important;
  }
}
</style>
